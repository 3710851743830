











































import SKPosition from '@/components/SK-Position.vue';
import { IPosition } from '@/utils/interfaces';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKPosition
  }
})
export default class LikedPositions extends Vue {
  get liked(): string[] {
    return this.$store.getters.user.likedPositions;
  }

  get positions(): IPosition[] {
    return this.$store.getters.positions;
  }

  get likedPositions(): IPosition[] {
    return this.positions.filter(x => this.liked.includes(x._id));
  }
}
